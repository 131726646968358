.container-player {
    width: 100%;
    max-width: 400px;
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;
    position: relative;
    font-family: arial, helvetica, sans-serif;
}

.container-player [data-youtube-id] {
    position: relative;
    padding-top: 56.25%;
    height: 0;
    overflow: hidden;
}

.container-player .yt-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.container-player .player-poster {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    cursor: pointer;
    z-index: 1;
}

.container-player .player-poster:before {
    box-sizing: border-box;
    content: 'Play';
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    display: block;
    width: 50px;
    height: 50px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    padding-top: 18px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}